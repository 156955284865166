// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import TrashFile from "../../blocks/dashboard/src/TrashFile.web";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1";
import PaymentSettings from "../../blocks/stripepayments/src/PaymentSettings.web";
import PaymentCheckout from "../../blocks/stripepayments/src/PaymentCheckout.web";
import PaymentDetails from "../../blocks/stripepayments/src/PaymentDetails.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filecompression from "../../blocks/filecompression/src/Filecompression";
import Share from "../../blocks/share/src/Share";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import FacialTracking from "../../blocks/facialtracking/src/FacialTracking";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import PrivacySettings from "../../blocks/privacysettings/src/PrivacySettings";
import Videos from "../../blocks/videos/src/Videos";
import Servicespecificsettingsadmin from "../../blocks/servicespecificsettingsadmin/src/Servicespecificsettingsadmin";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Download1 from "../../blocks/download1/src/Download1";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PasswordStrength from "../../blocks/passwordstrength/src/PasswordStrength";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin.web";
import TermsAndConditions from "../../blocks/signuplogin/src/TermsAndConditions.web";
import Login from "../../blocks/signuplogin/src/Login.web";
import SiteMap from "../../blocks/signuplogin/src/SiteMap.web";
import NewsLetter from "../../blocks/signuplogin/src/NewsLetter.web";
import TwoFactorAuth from "../../blocks/signuplogin/src/TwoFactorAuth.web";
import Cfdatastoragetracker from "../../blocks/cfdatastoragetracker/src/Cfdatastoragetracker";
import Cfdesigncomplexity from "../../blocks/cfdesigncomplexity/src/Cfdesigncomplexity";
import Cfcloudencryption4 from "../../blocks/cfcloudencryption4/src/Cfcloudencryption4";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Dataencryption2 from "../../blocks/dataencryption2/src/Dataencryption2";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import ContactusPage from "../../blocks/contactus/src/ContactusPage.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Dashboard4 from "../../blocks/dashboard4/src/Dashboard4";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import SharingDashboard from "../../blocks/dashboard/src/SharingDashboard.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Cffrapi from "../../blocks/cffrapi/src/Cffrapi";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Slideshowcreator2 from "../../blocks/slideshowcreator2/src/Slideshowcreator2";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Subscriptionbilling from "../../blocks/subscriptionbilling/src/Subscriptionbilling.web";
import Subscription from "../../blocks/subscriptionbilling/src/Subscription.web";
import PaymentHistory from "../../blocks/subscriptionbilling/src/PaymentHistory.web";
import ManageSubscription from "../../blocks/subscriptionbilling/src/ManageSubscription.web";
import PhotosStorage from "../../blocks/subscriptionbilling/src/PhotosStorage.web";
import VideoStorage from "../../blocks/subscriptionbilling/src/VideoStorage.web";
import RecipesStorage from "../../blocks/subscriptionbilling/src/RecipesStorage.web";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Visualsearch2 from "../../blocks/visualsearch2/src/Visualsearch2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import Dataencryption from "../../blocks/dataencryption/src/Dataencryption";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import CustomisableEditprofile from "../../blocks/customisableuserprofiles/src/CustomisableEditProfile.web";
import PaymentSuccesfull from "../../blocks/subscriptionbilling/src/PaymentSuccesfull.web";
import BlogpostsManagement from "../../blocks/blogpostsmanagement/src/BlogpostsManagement.web";
import BlogpostsDetails from "../../blocks/blogpostsmanagement/src/BlogpostsDetails.web";
import PaymentFailed from "../../blocks/subscriptionbilling/src/PaymentFailed.web";
import PaymentOptions from "../../blocks/subscriptionbilling/src/PaymentOptions.web";
import PhotosPage from "../../blocks/landingpage/src/PhotosPage.web";
import MomentsPage from "../../blocks/landingpage/src/MomentsPage.web";
import FamilyTreePage from "../../blocks/landingpage/src/FamilyTreePage.web";
import RecipsPage from "../../blocks/landingpage/src/RecipsPage.web";
import UserProfileViewing from "../../blocks/dashboard/src/UserProfileViewing.web";
import MemoriesDashboard from "../../blocks/dashboard/src/MemoriesDashboard.web";
import ShowCreateGroup from "../../blocks/dashboard/src/ShowCreateGroup.web";

const routeMap = {
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  Contentmanagement1: {
    component: Contentmanagement1,
    path: "/Contentmanagement1"
  },
  PaymentSettings: {
    component: PaymentSettings,
    path: "/PaymentSettings",
    protected: true
  },
  BlogpostsManagement: {
    component: BlogpostsManagement,
    path: "/BlogpostsManagement",
  },
  BlogpostsDetails: {
    component: BlogpostsDetails,
    path: "/BlogpostsDetails"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Filecompression: {
    component: Filecompression,
    path: "/Filecompression"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: "/PhotoLibrary"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  FacialTracking: {
    component: FacialTracking,
    path: "/FacialTracking"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/ResetPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: "/PrivacySettings"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  Servicespecificsettingsadmin: {
    component: Servicespecificsettingsadmin,
    path: "/Servicespecificsettingsadmin"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  Download1: {
    component: Download1,
    path: "/Download1"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  PasswordStrength: {
    component: PasswordStrength,
    path: "/PasswordStrength"
  },
  Signuplogin: {
    component: Signuplogin,
    path: "/Signup"
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions"
  },
  Login: {
    component: Login,
    path: "/Login"
  },
  SiteMap: {
    component: SiteMap,
    path: "/SiteMap"
  },
  NewsLetter: {
    component: NewsLetter,
    path: "/NewsLetter"
  },
  CustomisableEditprofile: {
    component: CustomisableEditprofile,
    path: "/CustomisableEditprofile"
  },
  TwoFactorAuth: {
    component: TwoFactorAuth,
    path: "/TwoFactorAuth"
  },
  Cfdatastoragetracker: {
    component: Cfdatastoragetracker,
    path: "/Cfdatastoragetracker"
  },
  Cfdesigncomplexity: {
    component: Cfdesigncomplexity,
    path: "/Cfdesigncomplexity"
  },
  Cfcloudencryption4: {
    component: Cfcloudencryption4,
    path: "/Cfcloudencryption4"
  },
  Favourites: {
    component: Favourites,
    path: "/Favourites"
  },
  AddFavourites: {
    component: AddFavourites,
    path: "/AddFavourites"
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: "/Notificationsettings"
  },
  PaymentCheckout:{
    component:PaymentCheckout,
    path: "/PaymentCheckout"
  },
  PaymentDetails:{
    component:PaymentDetails,
    path: "/PaymentDetails"
  },
  PaymentFailed:{
    component: PaymentFailed,
    path: "/PaymentFailed"
  },
  Comment: {
    component: Comment,
    path: "/Comment"
  },
  CreateComment: {
    component: CreateComment,
    path: "/CreateComment"
  },
  Dataencryption2: {
    component: Dataencryption2,
    path: "/Dataencryption2"
  },
  VideoLibrary: {
    component: VideoLibrary,
    path: "/VideoLibrary"
  },
  ContactusPage: {
    component: ContactusPage,
    path: "/ContactusPage"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  PhotosPage:{
    component: PhotosPage,
    path: "/PhotosPage"
  },
  MomentsPage:{
    component: MomentsPage,
    path: "/MomentsPage"
  },
  FamilyTreePage:{
    component: FamilyTreePage,
    path: "/FamilyTreePage"
  },
  RecipsPage:{
    component: RecipsPage,
    path: "/RecipesPage"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Dashboard4: {
    component: Dashboard4,
    path: "/Dashboard4"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  SharingDashboard: {
    component: SharingDashboard,
    path: "/SharingDashboard"
  },
  ShowCreateGroup: {
    component: ShowCreateGroup,
    path: "/ShowCreateGroup"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Cffrapi: {
    component: Cffrapi,
    path: "/Cffrapi"
  },
  CameraAccess: {
    component: CameraAccess,
    path: "/CameraAccess"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  Slideshowcreator2: {
    component: Slideshowcreator2,
    path: "/Slideshowcreator2"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  Subscriptionbilling: {
    component: Subscriptionbilling,
    path: "/Subscriptionbilling",
  },

  PaymentOptions:{
    component: PaymentOptions,
    path: "/PaymentOptions"
  },

  PaymentSuccesfull: {
    component: PaymentSuccesfull,
    path: "/PaymentSuccesfull"
  },
  Subscription: {
    component: Subscription,
    path: "/Subscription",
    protected: true
  },
  ManageSubscription: {
    component: ManageSubscription,
    path: "/ManageSubscription"
  },
  PhotosStorage: {
    component: PhotosStorage,
    path: "/PhotosStorage"
  },
  VideoStorage: {
    component: VideoStorage,
    path: "/VideoStorage"
  },
  RecipesStorage: {
    component: RecipesStorage,
    path: "/RecipesStorage"
  },
  PaymentHistory: {
    component: PaymentHistory,
    path: "/PaymentHistory"
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2"
  },
  Visualsearch2: {
    component: Visualsearch2,
    path: "/Visualsearch2"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Likeapost2: {
    component: Likeapost2,
    path: "/Likeapost2"
  },
  Dataencryption: {
    component: Dataencryption,
    path: "/Dataencryption"
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  TrashFile: {
  component: TrashFile,
  path: "/Trash",
  },
  UserProfileViewing: {
  component: UserProfileViewing,
  path: "/UserProfileViewing",
  },
  MemoriesDashboard: {
    component: MemoriesDashboard,
    path: "/MemoriesDashboard"
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;