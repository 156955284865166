import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled, Card, CardContent } from "@material-ui/core";
import DOMPurify from 'dompurify'; 
import Header from "../../../components/src/Header.web";
// Customizable Area End
import MomentsPageController, {
    Props,
} from "./MomentsPageController";
import { photosBanner, recipeDesign1, recipeDesign2, recipeDesign3, recipeFeatureBg,photoStorageDesign, downArrow, recipeDesign8, recipeDesign10, familyTreePin, photoStorageDesign2, recipeDesign5 } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";

export default class PhotosPage extends MomentsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderFAQSection() {
        const { landingData } = this.state;

        return (
            <Container maxWidth="md" style={{ marginBottom: 40, marginTop: 30 }}>
                <div style={{ width: '100%', maxWidth: 920, marginTop: 50, margin: 'auto' }}>
                    {landingData.length > 0 && landingData[0]?.landing_page_faqs?.length > 0 ? (
                        landingData[0].landing_page_faqs.map((faq: any, index: any) => (
                            <Box
                                key={faq.id}
                                style={{
                                    borderRadius: '4px',
                                    marginBottom: '16px',
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        padding: '12px',
                                        cursor: 'pointer',
                                        alignItems: 'center',
                                        borderBottom: this.state.recipeFaq === index ? 'none' : '1px solid #D6D3D1',
                                    }}
                                    onClick={() => this.handleAccordian(index)}
                                    data-test-id={`coveracordian-${index + 1}`}
                                >
                                    <Typography style={{ fontFamily: 'Poppins', flex: 1, fontSize: 18, fontWeight: 700 }}
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.question ) }}>
                                        
                                    </Typography>
                                    <IconButton
                                        aria-expanded={this.state.recipeFaq === index}
                                        style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                                        size="small"
                                        aria-label="show more"
                                    >
                                        <img
                                            src={downArrow}
                                            style={{
                                                transform: this.state.recipeFaq === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                                width: 24,
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                                {this.state.recipeFaq === index && (
                                    <Box style={{ padding: '12px', borderBottom: '1px solid #D6D3D1' }}>
                                        <Typography
                                            style={{
                                                fontWeight: 400,
                                                fontFamily: 'Poppins',
                                                flex: 1,
                                                fontSize: 16,
                                                color: '#5D6063',
                                            }}
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer ) }}
                                        >
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Typography>No FAQs available</Typography>
                    )}
                </div>
            </Container>
        );
    }

    renderFeatureSection() {
        const { landingData } = this.state;

        return (
            <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 100,  position: "relative"}}>
                <BackgroundImageFeature>
                    <img src={recipeDesign8} className="none" style={{ width: "100%", maxWidth: "229px", position: 'absolute', right: 0, top: -130, height: 260 }} />
                    <img src={recipeDesign2} style={{ width: "100%", maxWidth: "115px", position: 'absolute', left: 0, top: 80, height: 122 }} />
                    <img src={recipeDesign10} style={{ width: "100%", maxWidth: "180px", position: 'absolute', right: 0, bottom: 700, height: 352, zIndex: "-1" }} />
                    <img src={familyTreePin} style={{ width: "100%", maxWidth: "390px", position: 'absolute', left: 0, top: 1840, zIndex: "-1" }} />
                    <img src={photoStorageDesign} style={{ width: "100%", maxWidth: "180px", position: 'absolute',  top:610, height: 262}} />
                    <img src={photoStorageDesign2}  className="none" style={{ width: "100%", maxWidth: "220px", position: 'absolute',  bottom:750, height: 260}} />
                    

                    <Typography style={webStyles.bestFeatures} className="siftedStyle">How Our Family Photo Storage Works</Typography>
                    <Typography style={webStyles.bestFeatures2 as any}className="siftedStyle" >MyKinship offers a seamless and secure way to manage your family’s digital photo collection. Here’s how it works</Typography>
                    <Box style={{ maxWidth: "1300px", width: "100%" }}>
                        {landingData.length > 0 && landingData[0]?.work_storages?.length > 0 ? (
                            landingData[0].work_storages.map((storage: any, index: any) => (
                                <Box
                                    key={index}
                                    style={{
                                        display: "flex",
                                        marginTop: "80px",
                                        flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                                    }}
                                   className="imgaeStorageparnt"
                                >
                                    <Box>
                                        <img
                                            src={storage.content_image_url}
                                            style={{ width: "610px", zIndex: 2 }}
                                            className="imgaeStorage"
                                        />
                                    </Box>
                                 
                                    <Box

                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            marginLeft: index % 2 === 0 ? 50 : 0,
                                            marginRight: index % 2 !== 0 ? 50 : 0,
                                            textAlign: index % 2 !== 0 ? "right" : "left",
                                        }}
                                        className="goTosifted"
                                    >
                                        <Typography
                                            style={{
                                                fontSize: 36,
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                            }}
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(storage.title ) }}
                                        >
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: 24,
                                                fontWeight: 400,
                                                fontFamily: "Poppins",
                                                marginTop: 10,
                                            }}
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(storage.description ) }}
                                        >
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Typography>No work storages available.</Typography>
                        )}
                    </Box>
                </BackgroundImageFeature>
            </Box>
        );
    }


    // Customizable Area End

    render() {
        // Customizable Area Start
        const { landingData } = this.state;
      

        return (
            //Merge Engine DefaultContainer
            <Box>
                <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick}
                getHeaderData={(data) => {
                    this.landingPageFilterAPi(data);
                    }} />
                <Box style={{
                    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${landingData[0]?.background_image_url})`,
                    backgroundSize: "cover",
                    width: "100%",
                    maxWidth: "1635px",
                    backgroundPosition: "center",
                    height: "850px",
                    display: "flex",
                    justifyContent: "end",

                }} className="firstbox">
                    <Box style={{ width: "100%", maxWidth: "666px", marginRight: 50 }} className="secondbox">
                        <Typography style={webStyles.HeaderText} className="headerText"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.title ) }}></Typography>
                        <Typography style={webStyles.subHeaderPhotos} className="headerTextsecond"
                         dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.description ) }}>
                        </Typography>
                        <Button data-test-id="btnGetStarted" onClick={() => this.handleGetStarted("/PhotosPage")} type="submit" style={webStyles.continueButtonPhotos}>
                            <Typography style={webStyles.continueText as any}>Get started</Typography>
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <CardPageStyle>

                        <img src={recipeDesign5} style={{ width: "100%", maxWidth: "297px", position: 'absolute', right: 194, top: -250, zIndex: 1 }} />
                        <img src={recipeDesign1} style={{ width: "100%", maxWidth: "297px", position: 'absolute', left: 0, top: -100, zIndex: 1 }} />
                        <img src={recipeDesign3} style={{ width: "100%", maxWidth: "142px", position: 'absolute', right: 0, top: -630, height: "300px" }} />

                        <Card style={{
                            width: "100%", zIndex: 2, maxWidth: "1133px", color: "#fff", borderRadius: 16, backgroundColor: "#fff", paddingTop: 0,
                            paddingRight: 30,
                            paddingBottom: 30,
                            paddingLeft: 30, boxShadow: "0 4px 8px rgba(0.5, 0.5, 0.5, 0.5)"
                        }}>
                            <CardContent>
                                <Typography style={{ fontWeight: 700, fontFamily: "Poppins", fontSize: 48, color: "#305328", textAlign: "center" }}
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.content_title ) }}>
                                </Typography>
                                <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "1C2110", marginTop: 10 }}
                                 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(landingData[0]?.content_description ) }}>
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardPageStyle>
                </Box>
                <PhotoPageStyle >
                    {landingData?.[0]?.benefits?.map((benefit: any, index: any) => (
                        <Box className="priceBox2" key={benefit.id}>
                            <Typography className="priceTitle2"  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefit.title ) }}
                            ></Typography>
                            <Box className="priceCenterDiv2">
                                <Typography className="priceSubTitle2"  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(benefit.description ) }} 
                                />
                            </Box>
                            <Button className="startBtn2" onClick={this.startNowBtn}>Start now</Button>
                        </Box>
                    )) || null}
                </PhotoPageStyle>
                {this.renderFeatureSection()}
                <Box style={{marginLeft:50}}>
                <Box style={webStyles.frequentlyBox}>
                    <Typography style={webStyles.frequentlyText2}>Frequently asked questions</Typography>
                    <Typography style={webStyles.simpleText}>Here are some of the most frequently asked questions about MyKinship’s Family Photo Storage Solutions.</Typography>
                </Box>
                {this.renderFAQSection()}

                <Box style={{ position: 'relative' }}>
                    <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>

                        <Typography style={webStyles.moreQuestions}>Do You Have More Questions about Our Family Photo Storage Online?</Typography>
                        <Typography style={webStyles.moreQuestions2}>MyKinship is an online digital storage service that specializes in organizing and preserving your family photos. If you have any additional questions regarding our service, don’t hesitate to contact us. </Typography>
                    </Box>
                </Box>
                </Box>
                <Footer navigation={this.props.navigation} id={""} />

            </Box>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    container: {
        display: "grid",
        gridTemplateColumns: "3fr 7fr",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        padding: "0.5rem 1rem",
        minHeight: "12rem",
        gap: "2rem",
        width: "32rem",
    },
    HeaderText: {
        fontSize: 72,
        fontWeight: 400,
        color: '#305328',
        fontFamily: 'Abygaer',
        marginTop: 130,
      
    },
    subHeaderPhotos: {
        color: '#305328',
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 24,
    },
    continueButtonPhotos: {
        backgroundColor: "#8CA74B",
        width: '292px',
        borderRadius: '8px',
        height: '56px',
        marginTop: '60px',
    },
    continueText: {
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontWeight: 700,
        fontSize: "16px",
        color: "#FFFFFF",
        textTransform: 'none'
    },
    familyText: {
        color: "#305328",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: 48
    },
    familyText2: {
        color: "#305328",
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: 48,
        textAlign: 'right',
    },
    contentText: {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 20,
        color: "#1C2110"
    },
    textContainer: {
        maxWidth: "656px",
        width: "100%",
    },
  
    bestFeatures: {
        color: "#305328",
        fontWeight: 700,
        fontSize: 48,
        fontFamily: "Poppins",
        marginTop: 30
    },
    backgroundImageBanner: {
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${photosBanner})`,
        backgroundSize: 'cover',
        width: '100%',
        maxWidth: "1635px",
        backgroundPosition: 'center',
        height: '850px',
        display: 'flex',
        justifyContent: "end",
    },
    frequentlyBox: { maxWidth: 1240, margin: 'auto', width: '100%' },
    bestFeatures2: {
        color: "#5D6063",
        width: "100%",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: 20,
        maxWidth: "1200px",
        marginTop:10
    },
    frequentlyText2: {
        fontSize: 36,
        fontFamily: 'Poppins',
        fontWeight: 700,
        color: '#1C1917',
        marginTop: 50
    },
    simpleText: {
        fontFamily: 'Poppins',
        fontSize: 18,
        fontWeight: 400,
        color: '#5D6063'
    },
    moreQuestions: {
        fontFamily: "Poppins",
        fontSize: 36,
        fontWeight: 700,
        color: "#1C1917"
    },
    moreQuestions2: {
        fontWeight: 400,
        fontSize: 18,
        color: "#5D6063",
        fontFamily: "Poppins",
        marginTop: 10,
        marginBottom: 70
    },
};
const BackgroundImageFeature = styled("div")({

    backgroundImage: `url(${recipeFeatureBg})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '2230px',
    display: 'flex',
    backgroundSize: 'cover',
    flexDirection: "column",
    alignItems: 'center',
   "@media only screen and (min-device-width: 210px) and (max-device-width: 386px)": {
height:"auto"
   },
   "@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
height:"auto"
   },
   "@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
height:"auto",
padding:"1rem"
   },
   
   
   
   })
const CardPageStyle = styled("div")({

 display: "flex", gap: 80, justifyContent: 'center', marginTop: 60, position: 'relative',
"@media only screen and (min-device-width: 210px) and (max-device-width: 386px)": {
padding:"1rem",
marginTop:"11rem"
},
"@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
padding:"1rem",
marginTop:"7rem"
},
"@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
padding:"1rem",
marginTop:"7rem"
},



})
const PhotoPageStyle = styled("div")({

    display: 'flex',
    justifyContent: 'center',
    marginTop: 60,
    gap: "50px",
    "@media only screen and (min-device-width: 0px) and (max-device-width: 386px)": {
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
        gap: "20px", 
    },
    "@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
        gap: "20px", 
    },
    "@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
        gap: "20px", 
    },
    
    "& .priceBox2": {
        width: '370',
        height: '398',
        boxShadow: "0px -2px 8px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        borderRadius: '16px',
        "@media only screen and (min-device-width: 0px) and (max-device-width: 386px)": {
            width: '100%', 
        },
        "@media only screen and (min-device-width: 386px) and (max-device-width: 600px)": {
            width: '100%',
        },
        "@media only screen and (min-device-width: 600px) and (max-device-width: 820px)": {
            width: '100%',
        },
    },
    "& .priceTitle2": {
        lineHeight: "44px",
        fontWeight: 700,
        fontSize: "36px",
        letterSpacing: '-1%',
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '5px',
        marginTop: '40px',
        textAlign: 'center'
    },
    "& .priceSubTitle2": {
        fontWeight: 400,
        boxSizing: "border-box",
        fontSize: "18px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        textAlign: 'center',
        lineHeight: "28px",
        padding: "18px 22px 18px 22px",
        backgroundColor: 'transparent !important',
    },
    "& .priceSubTitle2 span": {
        backgroundColor: "transparent !important",
    },
    "& .priceCenterDiv2": {
        height: '140px',
        backgroundColor: "#E8EDDB",
        textAlign: 'center',
        display: "flex",
        alignItems: "center",
        marginTop: 40
    },

    "& .priceCenterBox2": {
        backgroundColor: "#557C29",
        height: '140px'
    },
    "& .priceTag2": {
        lineHeight: "56px",
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: "64px",
        color: '#FFFFFF',
        textAlign: 'center',
        letterSpacing: '-1.5%',
        padding: '13px',
    },
    "& .dollar2": {
        lineHeight: "56px",
        fontWeight: 300,
        fontSize: "32px",
        fontFamily: 'Inter',
        color: '#FFFFFF',
        letterSpacing: '-1.5%',
    },
    "& .monthText2": {
        lineHeight: "28px",
        fontWeight: 400,
        fontSize: "20px",
        color: '#FFFFFF',
        marginBottom: '20px',
        textAlign: 'center',
        fontFamily: 'Poppins',
        display: "block",
        alignItems: "center",
    },
    "& .storageText2": {
        lineHeight: "28px",
        fontWeight: 400,
        fontSize: "20px",
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '40px',
        textAlign: 'center',
        marginTop: '20px',
    },
    "& .startBtn2": {
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        marginTop: '40px',
        marginBottom: '48px',
        padding: '10px',
        marginLeft: "auto",
        marginRight: "auto",
        textTransform: "none",
        display: "block",
        width: '182px',
        "& .Muibutton-label": {
            lineHeight: "24px",
            fontWeight: 700,
            fontFamily: 'Poppins',
            color: '#FFFFFF',
            fontSize: "16px",
            textAlign: 'center',
        }
    }
})




// Customizable Area End
